import React, { useContext, useEffect, useState, useMemo } from "react";
import UICarousel from "ui/UICarousel";
import GlobalContext from "contexts/context";
import DatabaseContext from "data/contextDatabase";
import ThumbsContext from "contexts/contextThumbs";
import UIPersonaCard from "./UIPersonaCard";
import "./UIPersonaCard.css";
import logo from "../../src/person_outline2.png";
import UIProfileCard from "./UIProfileCard";
import { randomString } from "hooks/helper";
// import { timeoutGet, timeoutPost, timeoutDelete } from "hooks/http";
import { workerPost, workerGet } from "workers/interfaceRest";
import UISearch from "./UISearch";
import DropdownTopicMenu from "ui/Dropdown/dropdownTopicMenu";
import { BiCollection } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaDev, FaSearch, FaShareAlt } from "react-icons/fa";
import { FiArrowLeftCircle, FiLogIn, FiLogOut } from "react-icons/fi";
import { GoMute, GoUnmute, GoCommentDiscussion } from "react-icons/go";
import { HiOutlineMail } from "react-icons/hi";
import { IoVolumeMute, IoVolumeHigh } from "react-icons/io5";
import { BsFileEarmarkMedical } from "react-icons/bs";
import { HiOutlineUserGroup } from "react-icons/hi";
import { IoIosLogIn, IoIosLogOut } from "react-icons/io";
import { IoSettingsSharp } from "react-icons/io5";
import { MdOutlineEmail, MdOutlinePrivacyTip } from "react-icons/md";
import PrivacyPolicy from "./icons/PrivacyPolicy";
import { RiInboxUnarchiveLine, RiInboxArchiveLine } from "react-icons/ri";
import { TiTick } from "react-icons/ti";
import { VscFolderOpened } from "react-icons/vsc";
import "ui/UI.css";
import "ui/modals.css";
//import UINewUserModal from "ui/UINewUserModal";
import UINewUserLongModal from "ui/UINewUserLongModal";
import UIOTPModal from "ui/UIOTPModal";
import UISettings from "ui/UISettings";
import UILoginModal from "ui/UILoginModal";
import UICreateTopicModal from "ui/UICreateTopicModal";
import UIShareModal from "ui/UIShareModal";
import UserContext from "contexts/contextUser";
import UICreateNewPersona from "./UICreateNewPersona";
import TopicsContext from "contexts/contextTopics";
import Modals from "./Modals";
import PersonasContext from "contexts/contextPersonas";
import UISearchTopic from "./UISearchTopic";
import { useHistory } from "react-router-dom";
import { useLiveQuery } from "dexie-react-hooks";
import { dex_action } from "data/dexUtils";
import UIRequestPropertyModal from "./UIRequestPropertyModal";
import UINumTopicsModal from "./UINumTopicsModal";
import { isMobileTablet } from "hooks/helper";

const UIPersonaManager = (props) => {
  const { globalState, globalDispatch } = useContext(GlobalContext);
  const { databaseState } = useContext(DatabaseContext);
  const { thumbsState } = useContext(ThumbsContext);
  const { userDispatch } = useContext(UserContext);
  const { personasState } = useContext(PersonasContext);
  let history = useHistory();
  // const { suitesState } = useContext(SuitesContext);
  const { topicsState, topicsDispatch } = useContext(TopicsContext);
  const [modal, setModal] = useState();
  const [numUsers, setNumUsers] = useState(undefined);
  // const [contentModal, setContentModal] = useState();
  const [open, setOpen] = useState(false);
  const [createNewTopic, setCreateNewTopic] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [items, setItems] = useState([]);
  const [createPersona, setCreatePersona] = useState(false);
  const urlPersona = process.env.REACT_APP_PERSONA_API_URL;
  const keyPersona = process.env.REACT_APP_PERSONA_API_KEY;
  const [changeHgt, setChangeHgt] = useState(false);
  const [scrnHgt, setScrnHgt] = useState(0);
  const [startComponent, setStartComponent] = useState(true);
  const [outClick, setOutClick] = useState(false);
  const { userState } = useContext(UserContext);

  const liveMeta = useLiveQuery(() => {
    if (databaseState.dexUser) {
      let metadata = databaseState.dexUser.topic_metadata_buffer.toArray(
        (topics) => topics
      );
      return metadata || undefined;
    } else {
      return undefined;
    }
  }, [databaseState.dexUser]);

  const liveHideArchived = useLiveQuery(
    () =>
      databaseState.dexUser &&
      databaseState.dexUser.account.get("hideArchived"),
    [databaseState.dexUser]
  );
  useEffect(() => {
    if (
      globalState.emails?.length === 0 &&
      globalState.muid &&
      (globalState?.jwtLongExpiry || globalState?.jwt)
    ) {
      // alert("Please enter an email for future logins");
      props.setModal(
        <UIRequestPropertyModal
          onClose={() => props.setModal(undefined)}
          muid={globalState.muid}
          jwt={globalState?.jwtLongExpiry || globalState?.jwt}
        ></UIRequestPropertyModal>
      );
    }
  }, [
    globalState.emails,
    globalState.muid,
    globalState?.jwtLongExpiry,
    globalState?.jwt
  ]);

  useEffect(() => {
    function handleResize() {
      setScrnHgt(window.innerHeight * 0.9);
      setChangeHgt(true);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const getLastPersona = () => {
      let mpersona = localStorage.getItem("personaLogged");
      for (var i = 0; i < personasState.personas?.length; i++) {
        if (personasState.personas[i].mpersona === mpersona) {
          return personasState.personas[i];
        }
      }
      return personasState.personas[0]; //to handle the case where the value doesn't exist
    };
    let chk = personasState?.personas?.some(
      (element) => element.persona === globalState?.persona?.persona
    );
    if (!chk) {
      topicsDispatch({
        type: "SET_SHOWTOPIC",
        values: { showTopic: { subscription: undefined } }
      });
    }
    if (
      (globalState.persona === undefined || !chk) &&
      personasState.personas &&
      personasState.personas[0] !== undefined
    ) {
      globalDispatch({
        type: "SET_PERSONA",
        values: { persona: getLastPersona() }
      });
    }
    return () => {};
  }, [
    // globalDispatch,
    globalState.persona,
    personasState.personas
  ]);

  const onCreatePersona = useMemo(
    () => async (uid, password, accessToken, persona) => {
      const response = await workerPost(urlPersona, keyPersona, {
        type: "addpersona",
        uid: uid,
        pwd: password,
        persona: persona,
        token: accessToken,
        tag: "added from pwa",
        case_sensitive: "true",
        msgid: randomString(8)
      });
      workerGet(urlPersona, keyPersona, {
        type: "personalist",
        uid: uid,
        pwd: password,
        token: accessToken,
        msgid: randomString(8),
        version: globalState.version
      })
        .then((personaList) => {
          personaList?.list === undefined
            ? console.log()
            : dex_action({
                type: "DEX_PUT_IF_DIFF",
                values: {
                  db: databaseState.dexUser,
                  table: "account",
                  primaryKey: { type: "personas" },
                  doc: { type: "personas", value: personaList.list }
                }
              });
          setModal(undefined);
        })
        .catch((err) => {
          console.log("CATCH", err);
          setModal(undefined);
        });

      return response;
    },
    [databaseState.dexUser, globalState.version, keyPersona, urlPersona]
  );

  const closeCreateModal = () => {
    setCreatePersona(false);
    setModal(<div></div>);
  };

  const unreadSummary = useMemo(
    () => (mpersona) => {
      // console.log("!!!unreadSummary", liveMeta);
      return (
        liveMeta?.reduce(
          (acc, m) =>
            m.unreadCount && m.mpersona === mpersona ? acc + 1 : acc,
          0
        ) || 0
      );
    },
    [liveMeta]
  );

  // const unreadCollections = useMemo(
  //   () => (mpersona) => {
  //     let countSummary = {};
  //     let cnt = 0;
  //     topicsState?.topics &&
  //       topicsState.topics.forEach((t) => {
  //         let exclude = [];
  //         let tag = t.props?.tag || t.props.visibility;
  //         if (t.mpersona === mpersona && !exclude.includes(t.mtopic))
  //           countSummary[tag] =
  //             (countSummary[tag] || 0) +
  //             (countState?.count?.[`${t.mtopic}_${t.mpersona}`]?.unread > 0
  //               ? 1
  //               : 0);
  //       });

  //     Object.keys(countSummary).forEach((c) => {
  //       cnt += countSummary[c];
  //     });
  //     // for use in MARK_ALL_READ
  //     unreadMsgs += cnt;

  //     return cnt;
  //   },
  //   [topicsState, countState]
  //   // [dialogsState, subscriptionsState, topicsState, countState, suitesState]
  // );

  // const anyUnreadMsg = useMemo(
  //   () => () => {
  //     return unreadMsgs;
  //   },
  //   [topicsState, countState, personasState]
  // );

  const goFlagAsRead = useMemo(
    () => () => {
      setOpen(false);
      // if (anyUnreadMsg() > 0) {
      databaseState?.dexUser &&
        dex_action({
          type: "DEX_MULTI_MARK_READ",
          values: {
            db: databaseState.dexUser,
            global: true
          }
        });
      // unreadMsgs = 0;
      // }
    },
    [databaseState.dexUser]
  );

  const toggleArchive = useMemo(
    () => () => {
      setOpen(false);
      globalDispatch({
        type: "TOGGLE_ARCHIVE"
      });
    },
    []
  );

  const toggleFolders = useMemo(
    () => () => {
      setOpen(false);
      globalDispatch({
        type: "SET_PREFS",
        values: {
          prefs: {
            ...globalState.prefs,
            showFolders: !globalState?.prefs?.showFolders
          }
        }
      });
      dex_action({
        type: "DEX_PUT_IF_DIFF",
        values: {
          db: databaseState.dexUser,
          table: "user",
          primaryKey: { key: "prefs" },
          doc: {
            key: "prefs",
            value: {
              ...globalState.prefs,
              showFolders: !globalState?.prefs?.showFolders
            }
          }
        }
      });
    },
    [databaseState.dexUser, globalDispatch, globalState.prefs]
  );

  useEffect(() => {
    const btn_add_persona = {
      key: "btn_add_persona",
      id: "btn_add_persona",
      // header: <FiPlusCircle />,
      persona: "Add Persona",
      mpersona: "",
      onClick: () => setCreatePersona(true)
      // setModal(modalCreatePersona),
    };
    globalState.logging && console.log("personasState", personasState);
    let iList;
    globalState.showWorldIDScreen
      ? (iList = personasState?.personas
          ? personasState?.personas
              .map((persona) => {
                if (persona.mpersona === globalState?.persona?.mpersona)
                  return {
                    id: globalState.uid,
                    persona: globalState.uid,
                    mpersona: globalState.muid
                  };
                else return null;
              })
              .filter((persona) => persona !== null)
          : [])
      : (iList = personasState.personas
          ? personasState.personas.map((persona) => ({
              id: persona.persona,
              persona: persona.persona,
              mpersona: persona.mpersona,
              topicCnt: unreadSummary(persona.mpersona),
              onSecondClick: () => {
                if (!globalState.showPersonaScreen) {
                  history.push({
                    pathname: "/UIPersonaInfo",
                    state: { changedPic: true }
                  });
                  globalDispatch({
                    type: "SET_PERSONA_SCREEN",
                    values: { showPersonaScreen: true }
                  });
                }
              }
            }))
          : []);
    // let iList = personasState.personas
    //   ? personasState.personas.map((persona) => ({
    //       id: persona.persona,
    //       persona: persona.persona,
    //       mpersona: persona.mpersona,
    //       topicCnt: unreadSummary(persona.mpersona),
    //       // onClick: () => {
    //       //     globalDispatch({
    //       //       type: "SET_PERSONA",
    //       //       values: { persona: persona }
    //       //     });
    //       //   },
    //       onSecondClick: () => {
    //         if (!globalState.showPersonaScreen) {
    //           history.push({
    //             pathname: "/UIPersonaInfo",
    //             state: { changedPic: true }
    //           });
    //           globalDispatch({
    //             type: "SET_PERSONA_SCREEN",
    //             values: { showPersonaScreen: true }
    //           });
    //         }
    //       }
    //       // onLongPress: () => {
    //       //   document.getElementById("avatar_modal").style.display = "block";
    //       // },
    //     }))
    //   : [];
    if (personasState.personas?.length < 4 && !globalState.showWorldIDScreen)
      globalState.uid && (iList = iList.concat(btn_add_persona));
    setItemList([...iList]);
    return () => {};
  }, [
    globalDispatch,
    globalState.uid,
    personasState.personas,
    globalState.showPersonaScreen,
    globalState.showWorldIDScreen,
    personasState.personas,
    unreadSummary
  ]);

  useEffect(() => {
    const lists = document.querySelectorAll(".scroll-box__container");

    setItems(
      itemList.map((item) => {
        return (
          <UIPersonaCard
            header={{
              // preTitle: "Something small",
              // title: "Something big",

              image: globalState.showWorldIDScreen
                ? // ? userState?.userProfilePic
                  // : globalState.showWorldIDScreen
                  "profile"
                : thumbsState?.thumbs?.[item.mpersona] || "logo"
            }}
            // preTitle={"hellow world"}
            size={"small"}
            // title={"JONES"}
            body={{
              title: globalState.showWorldIDScreen
                ? globalState.uid
                : item.persona,
              text: ""
            }}
            key={item.mpersona}
            id={"card_" + item.mpersona}
            uid={globalState.uid}
            password={globalState.password}
            item={item}
            // onDeletePersona={onDeletePersona}
            selected={
              globalState.showWorldIDScreen
                ? true
                : globalState.persona?.mpersona === item.mpersona
            }
          ></UIPersonaCard>
        );
      })
    );

    lists.forEach((el) => {
      const n = itemList?.length;
      el.style.setProperty("--total", n);
    });
    return () => {};
  }, [
    globalState.password,
    globalState.persona?.mpersona,
    globalState.uid,
    thumbsState.thumbs,
    itemList,
    userState?.userProfilePic
  ]);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const goEnableSummaries = () => {
    setOpen(false);

    globalState?.jwt &&
      workerPost(urlPersona, keyPersona, {
        type: "r_user_reminder",
        opt_in: "true",
        client_url: process.env.REACT_APP_URL,
        jwt: globalState?.jwt
      })
        .then((result) => {
          alert(
            "You have subscribed to regular summary emails.\nYou can unsubscribe from within the email."
          );
        })
        .catch((e) => console.log(e));
  };

  const goPrivacyPolicy = () => {
    setOpen(false);
    process.env.REACT_APP_MODE === "dev" &&
      openInNewTab("https://www.enabled.world/privacy-policy/");
    process.env.REACT_APP_MODE === "prod" &&
      openInNewTab(
        "https://ezist.online/wp-content/uploads/2022/05/EZist-Privacy-Policy-22020505.pdf"
      );
  };

  const goLogin = useMemo(
    () => () => {
      setOpen(false);
      props.setModal(
        <UILoginModal
          onClose={() => props.setModal(undefined)}
          callback={goNewUser}
        ></UILoginModal>
      );
    },
    [props]
  );

  const goHideArchived = useMemo(
    () => () => {
      setOpen(false);
      databaseState.dexUser &&
        databaseState.dexUser.account.put({
          type: "hideArchived",
          value: true,
          time: Date.now()
        });
      history.goBack();
    },
    [databaseState.dexUser]
  );

  const goUnhideArchived = useMemo(
    () => () => {
      setOpen(false);
      databaseState.dexUser &&
        databaseState.dexUser.account.put({
          type: "hideArchived",
          value: false,
          time: Date.now()
        });
      history.goBack();
    },
    [databaseState.dexUser]
  );

  const goNumTopics = useMemo(
    () => () => {
      setOpen(false);
      props.setModal(
        <UINumTopicsModal
          onClose={() => {
            props.setModal(undefined);
          }}
          callback={() => {}}
        ></UINumTopicsModal>
      );
    },
    [props]
  );

  const handleClickOutside = () => {
    setOutClick(true);
    // setStartComponent(false)
    props.setModal(undefined);
    if (history.length > 1) history.goBack();
  };

  const goLogout = () => {
    setOpen(false);
    setStartComponent(false);
    props.setModal(
      <Modals
        // noBckgrnd={true}
        title="Sign Out"
        onClose={() => props.setModal(undefined)}
        onClickOutside={() => handleClickOutside()}
        footer={
          <div>
            <button
              className="UI-button-service"
              type="button"
              onClick={() => {
                props.setModal(undefined);
                dex_action({
                  type: "DEX_PUT",
                  values: {
                    db: databaseState.dexAdmin,
                    table: "store",
                    doc: {
                      key: "user"
                    },
                    cb: () => {
                      localStorage.removeItem("accessToken");
                      localStorage.removeItem("refreshToken");
                      localStorage.removeItem("idToken");
                      Object.keys(localStorage).forEach(
                        (key) =>
                          key.startsWith("key_") && localStorage.removeItem(key)
                      );
                      userDispatch({
                        type: "SET_TOKEN",
                        values: {
                          accessToken: null,
                          refreshToken: null,
                          idToken: null
                        }
                      });
                      window.location.reload();
                    }
                  }
                });
                // if (localStorage.getItem("loginproc") !== null)
                //   localStorage.removeItem("loginproc");
              }}
            >
              Sign Out
            </button>
          </div>
        }
      >
        <label className="modal-label-new">
          {globalState.emails?.length > 0
            ? `To sign back in you will need access to ${
                globalState.emails?.length > 1 ? "one of " : ""
              }the following email account${
                globalState.emails?.length > 1 ? "s:" : ":"
              }
              `
            : `To sign back in you will need your username and password.`}
          {globalState.emails.length > 0 &&
            globalState.emails.map((e) => (
              <label className="modal-label-new">
                <br />
                {e}
              </label>
            ))}
          {/* <br /> <br />
          All your messages will be deleted from this device! */}
          <br /> <br />
          Are you sure you want to sign out?
        </label>
      </Modals>
    );
  };

  const goNewUser = useMemo(
    () => () => {
      setOpen(false);
      if (true || process.env.REACT_APP_MODE === "dev") {
        props.setModal(
          <UINewUserLongModal
            onClose={() => props.setModal(undefined)}
            callback={goLogin}
          ></UINewUserLongModal>
        );
      }
    },
    [props]
  );

  const goSearchPersonas = () => {
    setOpen(false);
    props.setModal(
      <UISearch onClose={() => props.setModal(undefined)}></UISearch>
    );
  };

  const goSearchTopics = () => {
    setOpen(false);
    props.setModal(
      <UISearchTopic onClose={() => props.setModal(undefined)}></UISearchTopic>
    );
  };

  const goCreateTopic = () => {
    setOpen(false);
    setCreateNewTopic(true);
  };

  const goShare = () => {
    setOpen(false);
    props.setModal(
      <UIShareModal onClose={() => props.setModal(undefined)}></UIShareModal>
    );
  };

  const goValidate = () => {
    let bool_use_keycloak = localStorage.getItem("use_keycloak") === "true";
    let use_keycloak = bool_use_keycloak ? "false" : "true";
    // console.log(use_keycloak, globalState);
    globalState?.uid?.startsWith("dbl") &&
      localStorage.setItem("use_keycloak", use_keycloak);
    props.setModal(
      <UIOTPModal onClose={() => props.setModal(undefined)}></UIOTPModal>
    );
  };

  const goContactUs = () => {
    window.open("https://www.enabled.world/contributors/contact-us/");
  };

  const handleClose = () => {
    props.setModal(undefined);
  };

  const goSettings = () => {
    setOpen(false);
    props.setModal(<UISettings onClose={() => handleClose()}></UISettings>);
  };

  useEffect(() => {
    if (globalState.devMode === undefined)
      globalDispatch({
        type: "SET_DEV_MODE",
        values: { devMode: "false" }
      });
  }, []);

  // const showDevMode = globalState.devMode ? (

  //   <span>Normal Mode</span>
  // ) : (
  //   <span>Developer Mode</span>
  // );

  const goDev = () => {
    setOpen(false);
    let newVal = "true";
    if (globalState.devMode === newVal) newVal = "false";
    globalDispatch({
      type: "SET_DEV_MODE",
      values: { devMode: newVal }
    });
  };

  const toggleClientMute = () => {
    globalState.logging &&
      console.log("[UIPersonaManager] toggleClientMute prefs", globalState);
    globalDispatch({
      type: "SET_PREFS",
      values: {
        prefs: {
          ...globalState.prefs,
          clientMute: !globalState?.prefs?.clientMute
        }
      }
    });

    dex_action({
      type: "DEX_PUT",
      values: {
        db: databaseState.dexUser,
        table: "user",
        doc: {
          key: "prefs",
          value: {
            ...globalState.prefs,
            clientMute: !globalState?.prefs?.clientMute
          }
        }
      }
    });
    setOpen(false);
  };

  useEffect(() => {
    let mounted = true;
    let num = localStorage.getItem("used") === "true" ? 1 : 0;
    if (databaseState.dexAdmin) {
      databaseState.dexAdmin.dexNames.toArray(
        (d) => mounted && setNumUsers(d?.length || num)
      );
    } else {
      setNumUsers(num);
    }
    return () => {
      mounted = false;
    };
  }, [databaseState.dexAdmin]);

  useEffect(() => {
    let mounted = true;
    globalState.logging && console.log("numUsers", numUsers);
    if (databaseState.dexAdmin && !globalState.isLoggedIn) {
      setTimeout(() => {
        databaseState.dexAdmin.store.get({ key: "user" }).then((user) => {
          if (!user?.uid) {
            databaseState.dexAdmin.dexNames.toArray((d) => {
              if (mounted && d?.length > 0) goLogin();
              else mounted && goNewUser();
            });
          }
        });
      }, 2000);
    }
    return () => {
      mounted = false;
    };
  }, [
    globalState.accountDbInitiated,
    databaseState.dexAdmin,
    globalState.isLoggedIn,
    numUsers,
    goLogin,
    goNewUser
  ]);

  useEffect(() => {
    if (!startComponent) {
      window.addEventListener("popstate", props.onClose);
      if (
        window.location?.href?.includes("menu") ||
        window.location?.href?.includes("modal")
      ) {
        window.history.replaceState({ id: 2 }, null, "modal");
      } else {
        if (!props.loginModal) {
          window.history.pushState({ id: 2 }, null, "modal");
        }
      }
      return () => {
        window.removeEventListener("popstate", props.onClose);
      };
    }
  }, [goLogout, outClick, startComponent]);

  const menuItems = [
    {
      leftIcon: <FaSearch />,
      text: "Search Personas",
      callback: goSearchPersonas,
      permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
      goToMenu: ""
    },
    {
      leftIcon: <FaSearch />,
      text: "Search Topics",
      callback: goSearchTopics,
      permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
      goToMenu: ""
    },
    process.env.REACT_APP_MODE === "dev" && {
      leftIcon: <GoCommentDiscussion strokeWidth={2} />,
      text: "Create Topic",
      callback: goCreateTopic,
      permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
      goToMenu: ""
    },
    process.env.REACT_APP_MODE === "dev" && {
      leftIcon: <FaShareAlt />,
      text: "Share",
      callback: goShare,
      permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
      goToMenu: ""
    },
    {
      leftIcon: (
        <TiTick
          // strokeWidth={0}
          size={30}
        />
      ),
      text: "Mark all as read",
      callback: goFlagAsRead,
      permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
      goToMenu: ""
    },
    // {
    //   leftIcon: globalState?.displaying_archive ? (
    //     <RiInboxUnarchiveLine />
    //   ) : (
    //     <RiInboxArchiveLine />
    //   ),
    //   text: globalState?.displaying_archive
    //     ? "Show active topics"
    //     : "Show archived topics",
    //   callback: toggleArchive,
    //   permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
    //   goToMenu: ""
    // },
    {
      leftIcon: globalState?.prefs?.showFolders ? (
        <BiCollection strokeWidth={1} size={20} />
      ) : (
        <VscFolderOpened strokeWidth={1} size={20} />
      ),
      text: globalState?.prefs?.showFolders
        ? "Show collections"
        : "Show folders",
      callback: toggleFolders,
      permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
      goToMenu: ""
    },
    {
      leftIcon: globalState?.prefs?.clientMute ? (
        <IoVolumeHigh size={22} />
      ) : (
        <IoVolumeMute size={22} />
      ),
      text: globalState?.prefs?.clientMute ? "Unmute" : "Mute",
      callback: toggleClientMute,
      permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
      goToMenu: ""
    },
    {
      leftIcon: <BsFileEarmarkMedical strokeWidth={1} size={20} />,
      text: liveHideArchived?.value ? "Unhide Archived" : "Hide Archived",
      callback: liveHideArchived?.value ? goUnhideArchived : goHideArchived,
      permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
      goToMenu: ""
    },
    !process.env.REACT_APP_URL?.includes("ezist") &&
      !process.env.REACT_APP_URL?.includes("app") && {
        leftIcon: <IoSettingsSharp />,
        text: "Settings",
        callback: goSettings,
        permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
        goToMenu: ""
      },
    !process.env.REACT_APP_URL?.includes("ezist") &&
      !process.env.REACT_APP_URL?.includes("app") && {
        leftIcon: <FaDev />,
        text:
          globalState.devMode === false || globalState.devMode === "false"
            ? "Developer Mode"
            : "Normal Mode",
        callback: goDev,
        permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
        goToMenu: ""
      },
    (process.env.REACT_APP_URL?.includes("ezist") ||
      process.env.REACT_APP_URL?.includes("test.enabled.world")) && {
      leftIcon: (
        <HiOutlineMail
          strokeWidth={2.4}
          // MdOutlineEmail
          size={22}
        />
      ),
      text: "Email summaries",
      callback: goEnableSummaries,
      permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
      goToMenu: ""
    },
    {
      leftIcon: (
        <PrivacyPolicy
          strokewidth="4%"
          strokecolor="var(--menu_text_color)"
          fillcolor="var(--menu_text_color)"
        ></PrivacyPolicy>
      ),
      // <MdOutlinePrivacyTip
      // strokeWidth={1}
      // size={ 20} />,
      text: "Privacy Policy",
      callback: goPrivacyPolicy,
      permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
      goToMenu: ""
    },
    !process.env.REACT_APP_URL?.includes("ezist") &&
      !process.env.REACT_APP_URL?.includes("app") && {
        leftIcon: (
          <FiLogIn
            // IoIosLogIn
            strokeWidth={3}
            size={18}
          />
        ),
        text: "Sign in",
        callback: goLogin,
        permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
        goToMenu: ""
      },
    // {
    //   leftIcon: <BsFillPersonPlusFill />,
    //   text: "New User",
    //   callback: goNewUser,
    //   permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
    //   goToMenu: "",
    // },
    globalState.isLoggedIn && {
      leftIcon: (
        <FiLogOut
          // IoIosLogOut
          strokeWidth={3}
          size={18}
        />
      ),
      text: "Sign out",
      callback: goLogout,
      permissionLevels: ["admin", "owner", "designer", "tx", "whisper", "rx"],
      goToMenu: ""
    }
  ];

  const returnToTopics = () => {
    globalDispatch({
      type: "SET_PERSONA_SCREEN",
      values: { showPersonaScreen: false }
    });
    globalDispatch({
      type: "SET_WORLD_ID_SCREEN",
      values: { showWorldIDScreen: false }
    });
    if (history.length >= 1) history.goBack();
  };

  // const openWorldIDScreen = () => {
  //   globalDispatch({
  //     type: "SET_PERSONA_SCREEN",
  //     values: { showPersonaScreen: false },
  //   });
  //   globalDispatch({
  //     type: "SET_WORLD_ID_SCREEN",
  //     values: { showWorldIDScreen: true },
  //   });
  // };

  let content = (
    <div className="UI-persona-frame">
      {/* style={globalState.isLoggedIn
        ? {}
        : { display: "none" }}> */}
      {(globalState.showPersonaScreen || globalState.showWorldIDScreen) && (
        <FiArrowLeftCircle
          style={{
            fontSize: "3rem",
            color: "var(--menu_button_icon_color)"
          }}
          onClick={() => returnToTopics()}
        />
      )}
      <UICarousel items={items} />
      {!globalState.showWorldIDScreen && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          {globalState?.prefs?.clientMute && <IoVolumeMute size={28} />}
          <li className="UI-profile-menu">
            <span>
              <BsThreeDotsVertical
                size={28}
                // style={{ fontSize: "28px" }}
                onClick={() => setOpen(!open)}
              />
            </span>
            {open && (
              <DropdownTopicMenu
                roles={("admin", "owner", "designer", "tx", "whisper", "rx")}
                menuItems={menuItems}
                onClose={() => setOpen(false)}
                mainscreen={true}
                //   {/* // xOffset={-224}
                // // yOffset={-50} */}
              ></DropdownTopicMenu>
            )}
          </li>
          <UIProfileCard
            mpersona={globalState?.persona?.mpersona}
            header={{
              image:
                userState?.userProfilePic ||
                //       thumbsState?.thumbs?.[
                //       globalState?.persona?.mpersona
                // ]
                "profile"
            }}
            size={"small"}
            body={{
              // title: item.persona,
              text: ""
            }}
            key="profile"
            id="profile"
            uid={globalState.uid}
            password={globalState.password}
            // item={item}
            // selected={globalState.persona?.mpersona === item.mpersona}
          ></UIProfileCard>
        </div>
      )}
      {createPersona && <UICreateNewPersona cb={closeCreateModal} />}
      {createNewTopic && (
        <UICreateTopicModal
          onClose={() => setCreateNewTopic(false)}
        ></UICreateTopicModal>
      )}
      {/* {!globalState.isLoggedIn && <UIFlash />} */}
      {modal}
    </div>
  );
  return content;
};

export default React.memo(UIPersonaManager, (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});
