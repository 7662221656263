import React, { useState, useEffect } from "react";
import "ui/serviceMsg.css";
import "ui/UI.css"
import { buttonTypes } from "./buttonTypes";
import DateSelector from "./DateSelector";
import removeMarkdown from 'remove-markdown';

const ServMsgPview = (props) => {
  const [layout, setLayout] = useState(<div></div>);
  const communityStatus = ["community:status", "community_status"];

  const titleStyle = {
    fontSize: "var(--service_message_fontsize)"
  };

  const styleBtnOne = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#008080"
  };
  const styleBtnGeneral = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ADD5D5"
  };
  const getIndex = (value, arr, prop) => {
    for (var i = 0; i < (arr?.length || 0); i++) {
      if (arr[i][prop] === value) {
        return i;
      }
    }
    return -1; //to handle the case where the value doesn't exist
  };

  const displayEzistBtns = () => {
    //we have an array (props.pv)
    return props.pv.map((item, index) => (
      <button
        key={index}
        className="ezist_btn preview"
        style={
          getIndex(item, buttonTypes, "title") !== -1
            ? styleBtnOne
            : styleBtnGeneral
        }
        // title={item ? item : ""}
      >
        <span className="ezist_btn_individual">
          {getIndex(item, buttonTypes, "title") > -1 ? (
            buttonTypes[getIndex(item, buttonTypes, "title")].icon
          ) : (
            <span>{item}</span>
          )}
        </span>
      </button>
    ));
  };

  const dispButtons = () => {
    return props.pv.map((item, index) => (
      <button key={index} className="UI-button-service_preview">
        <span
        // className="ezist_btn_individual"
        >
          {item}
        </span>
      </button>
    ));
    // <button
    //   className="UI-button-service preview"
    //   // title="item ? item : "
    // >
    //   <span>
    //     {props.pv}
    //   </span>
    // </button>
  };

  useEffect(() => {
    const convtoUpper = (text) => {
      return text.toUpperCase();
    };

    const InsLineBreaks = (txt) => {
      let lines = txt.split("\n");

      return (
        <span>
          {lines.map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </span>
      );
    };

    const popupItem = (parent, item, liveTopics) => {
      if (!props.codeExecuted) {
        let btn = (
          <div>
            <button
              className={
                // props.message.parameters.service_msg.id === "community:status"
                communityStatus.includes(
                  props.message.parameters.service_msg.id
                )
                  ? "ezist_btn preview"
                  : "UI-button-service preview"
              }
              type={false ? "" : "greyOpacity"}
              style={
                props.codeExecuted
                  ? { display: "none" }
                  : communityStatus.includes(
                      props.message.parameters.service_msg.id
                    )
                  ? // props.message.parameters.service_msg.id ===
                    //   "community:status"
                    getIndex(item.title, buttonTypes, "title") !== -1
                    ? styleBtnOne
                    : styleBtnGeneral
                  : {}
              }
              title={item.title ? item.title : ""}
            >
              <span
                style={
                  communityStatus.includes(
                    props.message.parameters.service_msg.id
                  )
                    ? // props.message.parameters.service_msg.id === "community:status"
                      {
                        fontSize: "2rem",
                        color: "white",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center"
                      }
                    : {}
                }
              >
                {communityStatus.includes(
                  props.message.parameters.service_msg.id
                ) ? (
                  // props.message.parameters.service_msg.id ===
                  //   "community:status"
                  getIndex(item.title, buttonTypes, "title") > -1 ? (
                    buttonTypes[getIndex(item.title, buttonTypes, "title")].icon
                  ) : (
                    <span>{item.title}</span>
                  )
                ) : (
                  <span>{item.title}</span>
                )}
              </span>
            </button>
            {communityStatus.includes(
              props.message.parameters.service_msg.id
            ) && (
              // props.message.parameters.service_msg.id === "community:status"
              <span
                style={
                  item.title?.length < 9
                    ? {
                        fontSize: "0.7rem",
                        display: "flex",
                        justifyContent: "center"
                      }
                    : {
                        fontSize: "0.7rem",
                        display: "flex",
                        textAlign: "center",
                        justifyContent: "center"
                      }
                }
              >
                {item.title}
              </span>
            )}
          </div>
        );
        return <div> {btn}</div>;
      } else return null;
    };

    const linkItem = (parent, item, liveTopics) => {
      if (!props.codeExecuted) {
        let btn = (
          <div>
            <button
              className={
                communityStatus.includes(
                  props.message.parameters.service_msg.id
                )
                  ? // props.message.parameters.service_msg.id === "community:status"
                    "ezist_btn preview"
                  : "UI-button-service preview"
              }
              type={"preview"}
              style={
                communityStatus.includes(
                  props.message.parameters.service_msg.id
                )
                  ? // props.message.parameters.service_msg.id === "community:status"
                    getIndex(item.title, buttonTypes, "title") !== -1
                    ? styleBtnOne
                    : styleBtnGeneral
                  : {}
              }
              title={item.title ? item.title : ""}
            >
              <span
                style={
                  communityStatus.includes(
                    props.message.parameters.service_msg.id
                  )
                    ? // props.message.parameters.service_msg.id === "community:status"
                      {
                        fontSize: "3.2rem",
                        color: "white",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center"
                      }
                    : {}
                }
              >
                {communityStatus.includes(
                  props.message.parameters.service_msg.id
                ) ? (
                  // props.message.parameters.service_msg.id ===
                  //   "community:status"
                  getIndex(item.title, buttonTypes, "title") > -1 ? (
                    buttonTypes[getIndex(item.title, buttonTypes, "title")].icon
                  ) : (
                    <span>{item.title}</span>
                  )
                ) : (
                  <span>{item.title}</span>
                )}
              </span>
            </button>
            {communityStatus.includes(
              props.message.parameters.service_msg.id
            ) && (
              // props.message.parameters.service_msg.id === "community:status"
              <span
                style={
                  item.title?.length < 9
                    ? {
                        fontSize: "0.7rem",
                        display: "flex",
                        justifyContent: "center"
                      }
                    : {
                        fontSize: "0.7rem",
                        display: "flex",
                        textAlign: "center",
                        justifyContent: "center"
                      }
                }
              >
                {item.title}
              </span>
            )}
          </div>
        );
        return <div>{btn}</div>;
      } else return null;
    };

    let counter = 0;
    const nested = (parent, items, classStyle1, classStyle2) => {
      let objects = items.map((item, indx) => {
        counter = counter + 1;
        switch (item.type) {
          case "link":
            return linkItem(props.message.smid + "_" + item.id, item, []);
          case "pop-up":
            return popupItem(props.message.smid + "_" + item.id, item, []);
          case "dropdown":
            let children = undefined;
            let dropdown = (
              <div
                className={classStyle1 === "lines" ? "lines" : null}
                key={"div_" + props.message.smid + "_" + item.id}
              >
                <select
                  className={"lines-inner"}
                  disabled={item.locked === "true"}
                  id={props.message.smid + "_" + item.id}
                  style={{
                    fontSize: "var(--service_message_fontsize)",
                    minWidth: "10rem",
                    border: "1px solid var(--modal_border_color)",
                    borderRadius: "var(--border_radius_small)"
                  }}
                >
                  <option value={item.title} id="none" key="title">
                    {convtoUpper(item.title)}
                  </option>
                  {Array.isArray(item?.options) &&
                    item.options.map((option, index) => {
                      let v = (
                        <option
                          key={option.id}
                          value={option.title}
                          id={option.id}
                          position={item.position}
                          selected={false}
                        >
                          {option.title}
                        </option>
                      );
                      if (false && option.items) {
                        children = nested(
                          props.message.smid + "_" + option.id,
                          option.items,
                          "lines",
                          "na"
                        );
                      }
                      return v;
                    })}
                </select>
                {/* </div > */}
                {children}
              </div>
            );
            return dropdown;
          case "checkbox_group":
            let checkbox_group = (
              <div
                style={{
                  // marginLeft: "0.75rem",
                  fontSize: "var(--service_message_fontsize)",
                  marginTop: "0.25rem",
                  marginBottom: "0.25rem"
                }}
              >
                <div>{item.title}</div>
                <div>
                  {Array.isArray(item?.boxes) &&
                    item?.boxes.map((box) => (
                      <div
                        className={
                          props.type === "right"
                            ? "right-align-label"
                            : "left-align-label"
                        }
                        key={"div_" + props.message.smid + "_" + box.id}
                      >
                        <label>
                          <input
                            className="checkbox"
                            type="checkbox"
                            id={props.message.smid + "_" + box.id}
                            checked={false}
                          />
                          {box.title}
                        </label>
                      </div>
                    ))}
                </div>
              </div>
            );
            return checkbox_group;
          case "radio_group":
            let radio_group = (
              <div key={"div_" + props.message.smid + "_" + item.id}>
                {Array.isArray(item?.radio_buttons) &&
                  item?.radio_buttons.map((radio) => (
                    <div
                      key={"div_" + props.message.smid + "_" + radio.id}
                      className={
                        props.type === "right"
                          ? "right-align-label"
                          : "left-align-label"
                      }
                    >
                      <input
                        disabled={item.locked === "true"}
                        type="radio"
                        id={props.message.smid + "_" + radio.id}
                        name={props.message.smid + "_" + item.id}
                        value={radio.title}
                        checked={false}
                      />
                      <label htmlFor={props.message.smid + "_" + radio.id}>
                        <span>{radio.title}</span>
                      </label>
                    </div>
                  ))}
              </div>
            );
            return (
              <div
                className="radio-header"
                key={"div_" + props.message.smid + "_" + item.id}
              >
                {item.title}
                {radio_group}
              </div>
            );
          case "button_group":
            if (!props.codeExecuted) {
              let button_group =
                Array.isArray(item?.buttons) &&
                item?.buttons.map((button) => {
                  let buttonState = true;
                  switch (button.type) {
                    case "pop-up":
                      return popupItem(
                        props.message.smid + "_" + button?.id,
                        button,
                        []
                      );
                    case "link":
                      return linkItem(
                        props.message.smid + "_" + button?.id,
                        button,
                        []
                      );
                    default:
                      if (!props.codeExecuted) {
                        return (
                          <div>
                            <button
                              key={
                                "div_" +
                                props.message.smid +
                                "_" +
                                button.id +
                                "_" +
                                button.position
                              }
                              className="UI-button-service preview"
                              style={false ? {} : { opacity: "0.5" }}
                              // type="default"
                            >
                              {button.action === "goto" &&
                              ![]
                                .map(
                                  (t) =>
                                    t.mpersona === props.message.recipient &&
                                    t.mtopic
                                )
                                .includes(button.target)
                                ? `${button.alttitle || button["alt.title"]}`
                                : button.title}
                            </button>
                            {button.items &&
                              buttonState &&
                              nested(
                                props.message.smid,
                                button.items,
                                "lines",
                                "na"
                              )}
                          </div>
                        );
                      } else return null;
                  }
                });
              return (
                <div
                  className={
                    communityStatus.includes(
                      props.message.parameters.service_msg.id
                    )
                      ? // props.message?.parameters?.service_msg?.id ===
                        // "community:status"
                        // props.message.topic.toUpperCase().includes("STARWOOD") ||
                        // props.message.topic.toUpperCase().includes("COURT"))
                        "ezist-btn-layout_preview"
                      : "button-layout-service preview"
                  }
                  // style={
                  //   props.message?.parameters?.service_msg?.id ===
                  //     "community:status"
                  //     ? { background: "blue" }
                  //     : {}
                  // }
                  key={"div_" + props.message.smid + "_" + item.id}
                  type={item?.buttons[0]?.type}
                >
                  {button_group}
                  {/* {!txtFldPreview.current?.length <= 0
                  && button_group} */}
                  {/* {!document.getElementById("textfieldpreview")
                  && { button_group }} */}
                </div>
              );
            } else return null;
          case "date_input":
            //removed the follwing code and inserted the current date and time as initialDate
            // let initialDate = new Date(
            //   svcMsgState.response?.filter(
            //     (r) => r.id === props.message.smid + "_" + item.id
            //   )[0]?.value || item.date
            // );
            let initialDate = new Date();
            let date_input = (
              <div key={"div_" + props.message.smid + "_" + item.id}>
                {/* {item.title} */}
                <DateSelector
                  label={item.title}
                  shouldCloseOnSelect={false}
                  readOnly={item.readOnly === "true" ? true : false}
                  selected={initialDate || new Date()}
                  timeInputLabel="Time:"
                  dateFormat={item.format || "DD MMM yyyy HH:mm"}
                  showTimeInput
                  value={initialDate || new Date()}
                />
              </div>
            );
            return date_input;
          case "text_input":
            props.setCodeExecuted(true);
            let text_input = (
              <div
                // className="textfield"
                key={"div_" + props.message.smid + "_" + item.id}
              >
                <label
                  for={"txtinput_" + props.message.smid + "_" + item.id}
                  style={
                    item?.title?.length > 0 ? titleStyle : { display: "none" }
                  }
                >
                  {item.title}
                </label>
                <input
                  style={{
                    // marginLeft: "0.75rem",
                    paddingLeft: "0.5rem",
                    fontSize: "var(--service_message_fontsize)",
                    border: "1px solid var(--modal_border_color)",
                    borderRadius: "var(--border_radius_small)"
                  }}
                  type="text"
                  placeholder={item.hint}
                  value={item.default}
                  pattern={item.regex}
                  id={"txtinput_" + props.message.smid + "_" + item.id}
                  name={"txtinput_" + props.message.smid + "_" + item.id}
                />
              </div>
            );
            return text_input;
          default:
            return undefined;
        }
      });
      return objects;
    };

    setLayout(
      <div className="UI-message_preview">
        {props.type === "text"
          ? removeMarkdown(props.pv)
          : props.type === "button_group"
          ? communityStatus.includes(props.id)
            ? // props.id === "community:status"
              displayEzistBtns()
            : dispButtons()
          : "dispButtons()"}
      </div>
    );
    return () => {};
  }, [props]);

  return layout;
};

export default React.memo(ServMsgPview, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
