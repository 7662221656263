import React, { useContext, useEffect } from "react";

import GlobalContext from "contexts/context";
import DatabaseContext from "data/contextDatabase";
import UserContext from "contexts/contextUser";

import {
  useImageUploadsDex,
  useUploadDescriptorsDex,
  useUploadsDex,
  useUploadThemesDex,
  useUploadTopicDescriptorsDex
} from "hooks/dexHooks";

import { randomString } from "hooks/helper";
import { workerGet, workerPost } from "workers/interfaceRest";
import { uploadToS3 } from "connectivity/s3";

import { dex_action } from "data/dexUtils";
import { useLiveQuery } from "dexie-react-hooks";

const timer = new Date().getTime();

const urlPersona = process.env.REACT_APP_PERSONA_API_URL;
const keyPersona = process.env.REACT_APP_PERSONA_API_KEY;
const keyTheme = process.env.REACT_APP_JWT_S3_API_KEY;
const urlTheme = process.env.REACT_APP_JWT_S3_API_URL;

const Uploader = (props) => {
  const { userState } = useContext(UserContext);
  const { globalState } = useContext(GlobalContext);
  const { databaseState } = useContext(DatabaseContext);
  const { liveImageUploads } = useImageUploadsDex(databaseState.dexUser);
  const { liveUploads } = useUploadsDex(databaseState.dexUser);
  const { liveDescriptorUploads } = useUploadDescriptorsDex(
    databaseState.dexUser
  );
  const { liveTopicDescriptorUploads } = useUploadTopicDescriptorsDex(
    databaseState.dexUser
  );

  const { liveThemeUploads } = useUploadThemesDex(databaseState.dexUser);

  const jwt = useLiveQuery(
    () =>
      databaseState.dexAdmin &&
      databaseState.dexAdmin.store.get({ key: "user" }).then((u) => u?.jwt),
    [databaseState.dexAdmin]
  );

  useEffect(() => {
    let mounted = true;
    globalState.connected &&
      liveDescriptorUploads &&
      liveDescriptorUploads.forEach((lul) => {
        // TODO: convert to multiple promises and remove all the successes in one change
        // upload to s3
        mounted &&
          uploadToS3(lul)
            .then((r) => {
              globalState.logging &&
                console.log(
                  "[LiveData] uploadToS3 descriptorUploads result",
                  r
                );
              if (r.status === 200) {
                databaseState.dexUser.upload_descriptor
                  .where("mpersona")
                  .equals(lul.mpersona)
                  .and((item) => item.digest === lul.digest)
                  .delete()
                  .then((r) => {
                    globalState.logging &&
                      console.log(
                        "[LiveData] uploadToS3 descriptorUploads result",
                        r
                      );
                    // no need to update persona, as that is done by s3 triggering the "s3PutDescriptorSyncToPersonaDB" lambda
                    return r;
                  })
                  .catch((e) =>
                    console.log(
                      "[LiveData] uploadToS3 descriptorUploads error",
                      e
                    )
                  );
              }
            })
            .catch((err) => {
              console.log("CATCH", err);
            });
        // if successful, change in persona
      });
    return () => {
      mounted = false;
    };
  }, [globalState.connected, databaseState.dexUser, liveDescriptorUploads]);

  useEffect(() => {
    let mounted = true;
    globalState.connected &&
      liveTopicDescriptorUploads &&
      liveTopicDescriptorUploads.forEach((lul) => {
        // TODO: convert to multiple promises and remove all the successes in one change
        // upload to s3
        if (mounted) {
          // This needs a "roles" JWT
          let topicItem = { ...lul };
          delete topicItem._id;
          delete topicItem._deleted;
          delete topicItem._rev;
          // !!!TOKEN getJWT with auth token instead
          let jRole =
            userState?.accessToken || globalState.jwtLongExpiry
              ? {
                  type: "getJWT",
                  token: userState?.accessToken || globalState.jwtLongExpiry,
                  passwordless: globalState.jwtLongExpiry && "true",
                  mpersona: lul.mpersona,
                  entity: "roles",
                  instance: lul.mtopic,
                  msgid: randomString(8),
                  version: globalState.version
                }
              : {
                  type: "getJWT",
                  uid: globalState.uid,
                  pwd: globalState.password,
                  mpersona: lul.mpersona,
                  entity: "roles",
                  instance: lul.mtopic,
                  msgid: randomString(8),
                  version: globalState.version
                };
          workerGet(urlPersona, keyPersona, jRole)
            .then((response) => {
              globalState.logging &&
                console.log(
                  "[LiveData] topic design jwt",
                  response?.result?.jwt
                );
              let jwt = response?.result?.jwt;
              // got the jwt
              workerPost(urlTheme, keyTheme, {
                topic_descriptor: topicItem,
                jwt: jwt
              })
                .then((result) => {
                  // console.log("Topic_descriptor", result);
                  if (result !== "failed") {
                    dex_action({
                      type: "DEX_DELETE_WHERE",
                      values: {
                        db: databaseState.dexUser,
                        table: "upload_topic_descriptor",
                        where: { mpersona: lul.mpersona, digest: lul.digest }
                      }
                    });
                    // push topic descriptor number to the topic's properties on the messaging server
                    let m = {
                      type: "w.t.property.set",
                      smid: randomString(8),
                      mtopic: lul.mtopic,
                      mpersona: lul.mpersona,
                      property: "descriptor",
                      value: {
                        path:
                          "topic_descriptor/" +
                          lul.mtopic +
                          "/" +
                          lul.digest +
                          ".json",
                        digest: lul.digest
                      }
                    };
                    databaseState.dexUser &&
                      dex_action({
                        type: "DEX_PUT",
                        values: {
                          db: databaseState.dexUser,
                          table: "send",
                          doc: m
                        }
                      });
                  } else {
                    // s3 upload failed
                    alert("Failed to share topic design. error code 3");
                  }
                })
                .catch((err) => {
                  alert("Failed to share topic design. error code 2");
                  // s3 upload failed
                  console.log("Topic_descriptor CATCH", err);
                });
            })
            .catch((err) => {
              // jwt failed
              alert("Failed to share topic design. error code 1");
              console.log("CATCH", err);
            });
        }
      });
    return () => {
      mounted = false;
    };
  }, [
    globalState.connected,
    databaseState.dexUser,
    liveTopicDescriptorUploads
  ]);

  useEffect(() => {
    let mounted = true;
    globalState.connected &&
      liveImageUploads &&
      liveImageUploads.forEach((lul) => {
        mounted &&
          uploadToS3(lul)
            .then((r) => {
              // console.log("[LiveData] uploadToS3 result", r);
              if (r.status === 200) {
                dex_action({
                  type: "DEX_DELETE_WHERE",
                  values: {
                    db: databaseState.dexUser,
                    table: "upload_share_image",
                    where: { scope: lul.scope, digest: lul.digest }
                  }
                });
              }
            })
            .catch((err) => {
              console.log("CATCH", err);
            });
      });
    return () => {
      mounted = false;
    };
  }, [liveImageUploads, databaseState.dexUser, globalState.connected]);

  useEffect(() => {
    globalState.logging && console.log("JWT", jwt);
    let mounted = true;
    globalState.connected &&
      liveThemeUploads &&
      liveThemeUploads.forEach((lul) => {
        // TODO: convert to multiple promises and remove all the successes in one change
        // upload to s3
        let putItem = { ...lul };
        delete putItem._id;
        delete putItem._deleted;
        delete putItem._rev;
        // console.log("PUT_ITEM", JSON.stringify(putItem));
        mounted &&
          jwt &&
          workerPost(urlTheme, keyTheme, {
            theme: putItem,
            jwt: jwt
          })
            .then((r) => {
              globalState.logging &&
                console.log("[LiveData] uploadToS3 theme result", r, lul);
              if (r.ETag) {
                databaseState.dexUser.upload_theme
                  .delete(lul.id)
                  .then((r) => {
                    return r;
                  })
                  .catch((e) => console.log(e));
              }
            })
            .catch((err) => {
              console.log("CATCH", err);
            });
        // if successful, change in persona
      });
    return () => {
      mounted = false;
    };
  }, [databaseState.dexUser, liveThemeUploads, jwt]);

  useEffect(() => {
    let isMounted = true;
    // push to s3
    globalState.connected &&
      liveUploads &&
      liveUploads.forEach((lul) => {
        if (!lul._deleted && !lul.colors && lul.type !== "topic descriptor") {
          // not a Theme
          // console.log("[LiveData] uploadToS3", lul);
          uploadToS3(lul)
            .then((r) => {
              // console.log("[LiveData] uploadToS3 result", r);
              if (r.status === 200) {
                dex_action({
                  type: "DEX_DELETE_WHERE",
                  values: {
                    db: databaseState.dexUser,
                    table: "uploads",
                    where: { mpersona: lul.mpersona, digest: lul.digest }
                  }
                });
              }
            })
            .catch((err) => {
              console.log("CATCH", err);
            });
        }
      });
    return () => {
      isMounted = false;
    };
  }, [
    liveUploads,
    urlTheme,
    keyTheme,
    globalState.version,
    globalState.connected,
    databaseState.dexUser
  ]);

  globalState.logging &&
    console.log("Uploader timer", new Date().getTime() - timer);
  return null;
};

export default React.memo(Uploader, (prevProps, nextProps) => {
  return true;
});
