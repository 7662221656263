import React, { useContext, useState, useEffect } from "react";

import ThumbsContext from "contexts/contextThumbs";
import DatabaseContext from "data/contextDatabase";

import { useThumbsDex } from "hooks/dexHooks";
import { shouldLog } from "utils/shouldLog";
import moment from 'moment';

const timer = new Date().getTime();

const ThumbSetter = (props) => {
  const { databaseState } = useContext(DatabaseContext);
  const { thumbsDispatch } = useContext(ThumbsContext);
  const { liveThumbs } = useThumbsDex(databaseState.dexUser);
  const [updatedThumb, setUpdatedThumb] = useState(false)
  const [storedDateFin, setStoredDateFin] = useState(new Date(0));

  useEffect(() => {
    let mounted = true;
    let j = {};
    if (liveThumbs && mounted && !updatedThumb) {
      liveThumbs.forEach((t) => {
        const parsedXStoreDate = moment(t.storedate);
        if (parsedXStoreDate.isAfter(storedDateFin)) {
          setStoredDateFin(t.storedate);
          return (j[t.mpersona] = t.thumb);
        } else return {};
      });
      thumbsDispatch({
        type: "SET_THUMBS",
        values: { thumbs: j }
      });
      if (Object.keys(j)?.length > 0) setUpdatedThumb(true);
    }
    return () => {
      mounted = false;
    };
  }, [thumbsDispatch, liveThumbs]);

  shouldLog() && console.log("ThumbSetter timer", new Date().getTime() - timer);
  return null;
};

export default React.memo(ThumbSetter, (prevProps, nextProps) => {
  return true;
});
