import EXIF from "exif-js";
import { timestamp } from "hooks/helper";

import {
  storeDescriptor,
  digestCalc,
  storeImageScope,
  storeImageB64,
  assignDescriptor,
  upsertID
} from "data/descriptors";
import { randomString } from "hooks/helper";
import { shouldLog } from "./shouldLog";

// var deepEqual = require("deep-equal");
const isEqual = require("react-fast-compare");

export const orientation = (img) => {
  return new Promise(function (resolve, reject) {
    EXIF.getData(img, () => {
      var allMetaData = EXIF.getAllTags(img);
      shouldLog() && console.log("allMetaData: ", allMetaData);
      let orientation = allMetaData.Orientation;
      resolve(orientation);
    });
  });
};

// Images need to be resized for storing small and large versions in s3
export const resizeImage = (file, maxW, maxH) => {
  // console.log("resizeImage", file, maxW, maxH);
  return new Promise((resolve, reject) => {
    let rand = randomString(8);
    try {
      var img = document.createElement("img");
      // console.log("resizeImage img 0", img);
      img.setAttribute("id", "img_" + rand);
      img.setAttribute("key", "img_" + rand);
      // console.log("resizeImage img", img);
      img.onload = async () => {
        var canvas = document.createElement("canvas");
        canvas.setAttribute("id", "canvas_" + rand);
        canvas.setAttribute("key", "canvas_" + rand);
        var ctx = canvas.getContext("2d");
        var iw = img.width;
        var ih = img.height;
        var exifOrientation = "";
        // Bypassing use of orientation by adding 20 ...
        exifOrientation = 20 + (await orientation(img));
        let scale = 1;
        if (iw > maxW || ih > maxH) {
          scale = Math.min(maxW / iw, maxH / ih);
        }
        var iwScaled = Math.round(iw * scale);
        var ihScaled = Math.round(ih * scale);
        shouldLog() &&
          console.log("resize", iw, ih, maxW, maxH, scale, iwScaled, ihScaled);
        if ([5, 6, 7, 8].indexOf(exifOrientation) > -1) {
          canvas.width = ihScaled;
          canvas.height = iwScaled;
        } else {
          canvas.width = iwScaled;
          canvas.height = ihScaled;
        }
        shouldLog() && console.log("resize exiforientation=", exifOrientation);
        switch (exifOrientation) {
          case 2:
            ctx.setTransform(-1, 0, 0, 1, iwScaled, 0);
            break;
          case 3:
            ctx.setTransform(-1, 0, 0, -1, iwScaled, ihScaled);
            break;
          case 4:
            ctx.setTransform(1, 0, 0, -1, 0, ihScaled);
            break;
          case 5:
            ctx.setTransform(0, 1, 1, 0, 0, 0);
            break;
          case 6:
            ctx.setTransform(0, 1, -1, 0, ihScaled, 0);
            break;
          case 7:
            ctx.setTransform(0, -1, -1, 0, ihScaled, iwScaled);
            break;
          case 8:
            ctx.setTransform(0, -1, 1, 0, 0, iwScaled);
            break;
          default:
            ctx.setTransform(1, 0, 0, 1, 0, 0);
        }
        ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
        var dataURI = canvas.toDataURL();
        var testobj = { w: img.width, h: img.height, dataURI: dataURI };
        // console.log("resized", dataURI);
        resolve(testobj);

        // resolve(dataURI);
      };
      img.src = file;
    } catch (error) {
      reject(error);
    }
  });
};

export const imageToThumbAndPic = (file, maxThumb, maxPic) => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onloadend = async () => {
        // resizeImage uses a dummy canvas to size the image according to the maxWidth and maxHeight provides
        let thumbnail = await resizeImage(reader.result, maxThumb, maxThumb);
        let image = await resizeImage(reader.result, maxPic, maxPic);
        resolve({ image: image, thumbnail: thumbnail });
      };
      reader.readAsDataURL(file);
    } catch (error) {
      reject(error);
    }
  });
};

// example usage imageVersions(file, [{key: "image", maxWidth: 500, maxHeight: 500},{key: "thumbnail", maxWidth: 200, maxHeight: 200},{icon: "thumbnail", maxWidth: 16, maxHeight: 16}])
export const imageVersions = (file, versions) => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onloadend = async () => {
        // resizeImage uses a dummy canvas to size the image according to the maxWidth and maxHeight provides
        let promises = versions.map(async (v) => {
          return await resizeImage(reader.result, v.maxWidth, v.maxHeight).then(
            (i) => {
              // console.log("[IMAGE] resizeImage", v, i);
              let r = {};
              r[v.key] = i;
              return r;
            }
          );
        });
        let results = await Promise.all(promises);
        // console.log("[IMAGE] results", results);
        let final = {};
        results.forEach((r) => (final = { ...final, ...r }));
        resolve(final);
      };
      reader.readAsDataURL(file);
    } catch (error) {
      reject(error);
    }
  });
};

export const storeWallpaper = async (
  db,
  mtopic,
  img64,
  thumb64,
  descriptor
) => {
  let stagedImgDigest, stagedThumbDigest;
  if (img64) {
    stagedImgDigest = await digestCalc(img64)
      .then(async (digest) => {
        let result = await storeImageB64(db, "upload_image", mtopic, img64, {
          scope: mtopic
        });
        return result && digest;
      })
      .catch((e) => {
        return false;
      });
  }
  if (thumb64) {
    stagedThumbDigest = await digestCalc(thumb64)
      .then(async (digest) => {
        let result = await storeImageB64(db, "upload_image", mtopic, thumb64, {
          scope: mtopic
        });
        return result && digest;
      })
      .catch((e) => {
        return false;
      });
  }
};

export const deleteAvatar = async (
  db,
  newAvatar,
  parr,
  muid,
  mpersona,
  thumb64
) => {
  shouldLog() &&
    console.log("deleteAvatar", db, newAvatar, parr, muid, mpersona, thumb64);
  //only thing to change is picArr(picsarray)
  let descriptor = {
    mpersona: mpersona,
    imgdigest: newAvatar.imgdigest,
    imgpath: newAvatar.imgpath,
    thumbdigest: newAvatar.thumbdigest,
    thumbpath: newAvatar.thumbpath,
    storedate: timestamp(),
    picsarray: parr
  };
  let descriptorDigest = await digestCalc(JSON.stringify(descriptor));
  descriptor.digest = descriptorDigest;
  let check = await digestCalc(muid + mpersona + descriptorDigest);
  // console.log("calc check", check);
  descriptor.check = check;
  // store descriptor for upload
  if (descriptorDigest) {
    storeDescriptor(db, "upload_descriptor", descriptor);
    storeDescriptor(db, "descriptor", descriptor);
    await assignDescriptor(db, descriptor);
  }
};

export const changeAvatar = async (
  db,
  newAvatar,
  parr,
  muid,
  mpersona,
  thumb64
) => {
  let descriptor = {
    mpersona: mpersona,
    imgdigest: newAvatar.imgdigest,
    imgpath: newAvatar.imgpath,
    thumbdigest: newAvatar.thumbdigest,
    thumbpath: newAvatar.thumbpath,
    storedate: timestamp(),
    picsarray: parr
  };

  let descriptorDigest = await digestCalc(JSON.stringify(descriptor));
  descriptor.digest = descriptorDigest;
  let check = await digestCalc(muid + mpersona + descriptorDigest);
  // console.log("calc check", check);
  descriptor.check = check;
  // store descriptor for upload
  if (descriptorDigest) {
    storeDescriptor(db, "upload_descriptor", descriptor);
    storeDescriptor(db, "descriptor", descriptor);
    await assignDescriptor(db, descriptor);
    upsertID(db, "cache", {
      b64: thumb64,
      digest: newAvatar.imgdigest,
      scope: "image",
      storedate: timestamp()
    });
  }
};

export const storeAvatars = async (
  db,
  muid,
  mpersona,
  img64,
  thumb64,
  picarr,
  fname,
  worldIDInfo
) => {
  shouldLog() &&
    console.log(
      "!!!storeAvatars",
      db,
      muid,
      mpersona,
      img64,
      thumb64,
      picarr,
      fname
    );
  let stagedImgDigest = await digestCalc(img64)
    .then(async (digest) => {
      let result = await storeImageB64(db, "uploads", img64, {
        mpersona: mpersona
      });
      return result && digest;
    })
    .catch((e) => {
      return false;
    });
  let stagedThumbDigest = await digestCalc(thumb64)
    .then(async (digest) => {
      let result = await storeImageB64(db, "uploads", thumb64, {
        mpersona: mpersona
      });
      return result && digest;
    })
    .catch((e) => {
      return false;
    });
  console.log("!!!storeAvatars digests", stagedImgDigest, stagedThumbDigest);

  if (stagedImgDigest && stagedThumbDigest) {
    // create dscriptor
    let parr;
    picarr
      ? (parr = [
          {
            imgdigest: stagedImgDigest,
            imgpath: "image/" + mpersona + "/" + stagedImgDigest + ".json",
            thumbdigest: stagedThumbDigest,
            thumbpath: "image/" + mpersona + "/" + stagedThumbDigest + ".json",
            thumb64: thumb64,
            name: fname
          },
          ...picarr
        ])
      : (parr = [
          {
            imgdigest: stagedImgDigest,
            imgpath: "image/" + mpersona + "/" + stagedImgDigest + ".json",
            thumbdigest: stagedThumbDigest,
            thumbpath: "image/" + mpersona + "/" + stagedThumbDigest + ".json",
            thumb64: thumb64,
            name: fname
          }
        ]);
    let descriptor = {
      mpersona: worldIDInfo ? muid : mpersona,
      imgdigest: stagedImgDigest,
      imgpath: "image/" + mpersona + "/" + stagedImgDigest + ".json",
      thumbdigest: stagedThumbDigest,
      thumbpath: "image/" + mpersona + "/" + stagedThumbDigest + ".json",
      storedate: timestamp(),
      picsarray: parr
    };
    let descriptorDigest = await digestCalc(JSON.stringify(descriptor));
    descriptor.digest = descriptorDigest;
    let check = await digestCalc(muid + mpersona + descriptorDigest);
    // console.log("calc check", check);
    descriptor.check = check;
    // store descriptor for upload
    shouldLog() && console.log("!!!storeAvatars descriptor", descriptor);
    if (descriptorDigest) {
      storeDescriptor(db, "upload_descriptor", descriptor);
      storeDescriptor(db, "descriptor", descriptor);
      await assignDescriptor(db, descriptor);
      thumb64 &&
        stagedThumbDigest &&
        upsertID(db, "cache", {
          b64: thumb64,
          digest: stagedThumbDigest,
          scope: "image",
          storedate: timestamp()
        });
    }
  }
};

//???
export const storeImage = async (db, scope, img64) => {
  // scope: mpersona, mtopic, "public"
  let stagedImgDigest = await digestCalc(img64)
    .then((digest) => {
      return digest;
    })
    .catch((e) => {
      return false;
    });

  setTimeout(() => {
    storeImageScope(db, "upload_share_image", scope, img64, stagedImgDigest)
      .then((result) => {
        shouldLog() && console.log("storeImageScope", result, scope, img64);
        return result;
      })
      .catch((e) => {
        console.log("storeImageScope", e);
        return false;
      });
    if (img64 && img64.length < 200000) {
      upsertID(db, "cache", {
        b64: img64,
        digest: stagedImgDigest,
        scope: "image",
        storedate: timestamp()
      });
    }
  }, 500);

  return { scope: scope, digest: stagedImgDigest };
};

const compareDocs = (a, b) => {
  let c = { ...a, _rev: "", _deleted: a.undeleted };
  let d = { ...b, _rev: "", _deleted: b.undeleted };
  return isEqual(c, d);
};

export const deleteOldCache = (db, cacheType, scopeStart, dateCutoff) => {
  db &&
    db
      .allDocs({
        include_docs: false,
        startkey: "54cache4" + cacheType + "4",
        endkey: "54cache4" + cacheType + "5"
      })
      .then((r) => {
        // console.log("deleteOldCache", r);
        r.rows.forEach((row) => {
          db.get(row.id)
            .then((item) => {
              // console.log("deleteOldCache item", item);
              if (
                (item.scope?.startsWith(scopeStart) ||
                  item.mpersona?.startsWith(scopeStart)) &&
                Date.parse(item.storedate) < Date.parse(dateCutoff)
              ) {
                item._deleted = true;
                delete item.b64;
                delete item.digest;
                delete item.scope;
                delete item.storedate;
                db.put(item)
                  .then(() => {
                    shouldLog() && console.log("deleteOldCache deleted", item);
                  })
                  .catch((err) => console.log(err));
              }
            })
            .catch((err) => {
              console.log("CATCH", err);
            });
        });
      })
      .catch((err) => {});
};