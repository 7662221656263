import React, { useEffect, useRef, useContext, useState, useMemo } from "react";
import "ui/UI.css";
import "ui/UISS.css";
import "ui/UIButtons.css";
import GlobalContext from "contexts/context";
import DatabaseContext from "data/contextDatabase";
import TopicsContext from "contexts/contextTopics";
import UITopicList from "ui/UITopicList";
import UIPersonaManager from "ui/UIPersonaManager";
import { isMobileTablet } from "hooks/helper";
import StyleContext from "contexts/contextStyle";
import UIShareModal from "ui/UIShareModal";
import UIWorldIDInfo from "ui/UIWorldIDInfo";
import UISearchMyTopicsModal from "ui/UISearchMyTopicsModal";
import { CgArrowsMergeAltV, CgArrowsShrinkV } from "react-icons/cg";
import { MdOutlineFilterAltOff, MdOutlineFilterAlt } from "react-icons/md";
import { FaSearch } from "react-icons/fa";
import UIAlertModal from "./UIAlertModal";
import UIFlash from "./UIFlash";
import UIRegister from "./UIRegister";
import { useAccountDex } from "hooks/dexHooks";
import UITopicContainer from "./UITopicContainer";
import { s3ToDescriptor } from "connectivity/s3";
import UITopicTest from "./UITopicTest";
import UIParticipants from "./UIParticipants";
import ClickOutsideDetector from "utils/ClickOutsideDetector";
import LiveCacheContext from "data/contextLiveCache";
import UserProfile from "utils/UserProfile";

const UILogin = (props) => {
  const { globalState } = useContext(GlobalContext);
  const { styleState, styleDispatch } = useContext(StyleContext);
  const { databaseState } = useContext(DatabaseContext);
  const [modal, setModal] = useState(undefined);
  const [collectionDisplay, setCollectionDisplay] = useState("current");
  const { topicsState, topicsDispatch } = useContext(TopicsContext);
  const { liveUser } = useAccountDex(databaseState.dexAdmin);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [subgroup, setSubgroup] = useState();
  const maxColWidth = 600;
  const minColWidth = 300;
  const maxPartColWidth = 350;
  const [open, setOpen] = useState(false);
  const [mnuX, setMnuX] = useState(0);
  const [mnuY, setMnuY] = useState(0);
  const menuRef = useRef();
  const [highlight, setHighlight] = useState(false);
  const { liveCacheState } = useContext(LiveCacheContext);

  const getPromiseDescriptor = async () => {
    const getDescriptor = async (mpersona, digest) => {
      let descriptor = await databaseState.dexUser.descriptor
        .get({
          mpersona: mpersona,
          digest: digest
        })
        .then(async (d) => {
          if (d) return d;
          let fetched = await s3ToDescriptor("descriptor", mpersona, digest);
          // fetched?.digest && storeDescriptor(db, "descriptor", fetched);
          return fetched;
        })
        .catch(async (e) => {
          console.log(e);
        });
      return descriptor;
    };
    let desc = topicsState?.descriptorsB?.filter(
      (d) => d?.mpersona === globalState?.muid
    )[0];
    let descriptor = "";
    if (desc) descriptor = await getDescriptor(globalState.muid, desc.digest);
    // let testFetched = await s3ToDescriptor("descriptor", globalState.muid, testDigest);
    return descriptor;
  };

  useEffect(() => {
    const collectionState = liveCacheState.collectionState;
    const getCollectionStatus = () => {
      const getOpenVal = (cat, topicMPersona) => {
        let open = false;
        const catLowercase = cat.toLowerCase();
        if (
          Object.keys(collectionState).some(
            (key) => key.toLowerCase() === catLowercase
          )
        ) {
          let category = null;
          if (collectionState[catLowercase])
            category = collectionState[catLowercase];
          else if (collectionState[cat]) category = collectionState[cat];
          if (category) {
            let keys = Object.keys(category);
            for (let i = 0; i < keys.length; i++) {
              if (
                keys[i] === topicMPersona &&
                category[keys[i]].open === true
              ) {
                open = true;
                break;
              }
            }
          }
        }
        return open;
      };

      let isOpenValue = false;
      for (let x = 0; x < topicsState.topics?.length; x++) {
        //looking at each topic for first occurence of collection
        if (topicsState.topics[x]?.mpersona === globalState.persona?.mpersona) {
          //if globalState.displaying_archive is true then look at topics where
          //topicsState.topics[x]?.subprops?.archived is true
          //else look at topics where it it false
          if (globalState.displaying_archive) {
            if (topicsState.topics[x]?.subprops?.archived === "true") {
              let cat =
                topicsState.topics[x]?.props?.tag ||
                topicsState.topics[x]?.props?.visibility;
              isOpenValue = getOpenVal(cat, topicsState.topics[x]?.mpersona);
            }
          } else {
            if (topicsState.topics[x]?.subprops?.archived === "false") {
              let cat =
                topicsState.topics[x]?.props?.tag ||
                topicsState.topics[x]?.props?.visibility;
              isOpenValue = getOpenVal(cat, topicsState.topics[x]?.mpersona);
            }
          }
        }
        if (isOpenValue) break;
      }
      return isOpenValue;
    };
    setHighlight(getCollectionStatus());
  }, [
    liveCacheState,
    globalState.persona?.mpersona,
    topicsState.topics,
    globalState.displaying_archive
  ]);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
      // setScreenHeight(window.innerHeight);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [screenWidth]);

  useEffect(() => {
    setCollectionDisplay("current");
  }, [globalState?.persona?.mpersona]);

  useEffect(() => {
    databaseState.dbMaster &&
    styleState?.theme?.colors &&
    styleState?.theme?.fonts
      ? styleDispatch({
          type: "RESTORE_STYLE",
          values: { style: styleState?.theme }
        })
      : styleDispatch({
          type: "SET_DEFAULT",
          values: {}
        });
    return () => {};
  }, [styleDispatch, styleState?.theme, databaseState.dbMaster]);

  const showShare = useMemo(
    () => (title, link) => {
      setModal(
        <UIShareModal
          onClose={() => setModal(undefined)}
          title={title}
          link={link}
        ></UIShareModal>
      );
    },
    []
  );

  useEffect(() => {
    setModal(
      globalState.prevent_sending ? (
        <UIAlertModal
          secure={true}
          title="Information"
          message={"Cleaning up your database, please wait"}
          onClose={() => {}}
        ></UIAlertModal>
      ) : undefined
    );
    return () => {};
  }, [globalState.prevent_sending]);

  const [intFrameHeight, setIntFrameHeight] = useState(window.innerHeight);
  const [UIFrameHeight, setUIFrameHeight] = useState(window.innerHeight);

  useEffect(() => {
    function handleResize() {
      setIntFrameHeight(window.innerHeight);
      setUIFrameHeight(window.innerHeight);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(() => {
  //   if (menuRef.current) {
  //     if (menuRef.current.clientWidth > 0) {
  //       setMenuWidth(menuRef.current.clientWidth);
  //     }
  //     if (menuRef.current.clientHeight > 0) {
  //       setMenuHgt(menuRef.current.clientHeight);
  //     }
  //   }
  // }, [menuRef.current]);

  const unreadsCallback = () => {
    topicsDispatch({
      type: "SET_UNREAD_DISPLAY",
      values: { unreadDisplay: !topicsState.unreadDisplay }
    });
  };

  const flagCallback = () => {
    topicsDispatch({
      type: "SET_FLAG_DISPLAY",
      values: { flagDisplay: true }
    });
  };

  const labelCallback = (label) => {
    topicsDispatch({
      type: "SET_LABEL_DISPLAY",
      values: { labelDisplay: label }
    });
  };

  useEffect(
    () => {
      let found = false;
      for (let i = 0; i < globalState.prefs?.labelNamesList?.length; i++) {
        if (typeof globalState.prefs?.labelNamesList[i]?.labels === "object") {
          for (
            let j = 0;
            j < globalState.prefs?.labelNamesList[j]?.labels?.length;
            i++
          )
            if (
              globalState.prefs?.labelNamesList[i]?.labels[j]?.text ===
              topicsState.labelDisplay
            ) {
              found = true;
              break;
            }
        } else {
          if (
            globalState.prefs?.labelNamesList[i]?.labels?.includes(
              topicsState.labelDisplay
            )
          ) {
            found = true;
            break;
          }
        }
      }
      if (!found && topicsState.labelDisplay?.length > 0) {
        topicsDispatch({
          type: "SET_LABEL_DISPLAY",
          values: { labelDisplay: undefined }
        });
      }
    },
    [globalState.prefs?.labelNamesList, globalState.prefs?.labelList],
    topicsState.labelDisplay
  );

  const createMenuItems = () => {
    let menuItems = [
      {
        // icon:,
        text: "Unread",
        callback: unreadsCallback
      }
    ];
    if (
      globalState.prefs?.clientStar &&
      globalState.prefs?.clientStar?.length > 0
    ) {
      menuItems = [
        ...menuItems,
        {
          text: "Starred",
          callback: flagCallback
        }
      ];
    }
    globalState.prefs?.labelNamesList?.map((item, i) => {
      return item.labels.map((labelItem) => {
        if (typeof item.labels[0] === "object") {
          if (!menuItems.some((el) => el.text === labelItem.text)) {
            menuItems = [
              ...menuItems,
              {
                color: labelItem.color,
                text: labelItem.text,
                callback: labelCallback
              }
            ];
          }
        } else {
          if (!menuItems.some((el) => el.text === labelItem)) {
            menuItems = [
              ...menuItems,
              {
                text: labelItem,
                callback: labelCallback
              }
            ];
          }
        }
        return menuItems;
      });
    });
    return menuItems;
  };

  const loadMenu = () => {
    let labelsPres = false;
    if (globalState.prefs?.labelNamesList) {
      for (let i = 0; i < globalState.prefs?.labelNamesList?.length; i++)
        if (
          globalState.prefs?.labelNamesList[i].labels &&
          globalState.prefs?.labelNamesList[i].labels?.length > 0
        ) {
          labelsPres = true;
          break;
        }
    }
    if (
      labelsPres ||
      (globalState.prefs?.clientStar &&
        globalState.prefs?.clientStar?.length > 0)
    ) {
      let menuList = createMenuItems();
      let yPos;
      let numLinesH = menuList.length + 3;
      let availSpace = mnuY * 0.7;
      const element = document.getElementById("contentDiv");
      const computedStyle = window.getComputedStyle(element);
      const fontSize = computedStyle.getPropertyValue("font-size");
      const fontSizeValue = parseFloat(fontSize);
      const oneRem = fontSizeValue;
      const lineH = oneRem + oneRem / 2;
      let noAllwdLines = 0;
      let maxHgtMnu;
      if (availSpace > numLinesH * lineH) {
        maxHgtMnu = mnuY - numLinesH; // * lineH;
        noAllwdLines = numLinesH;
      } else {
        maxHgtMnu = availSpace;
        noAllwdLines = availSpace / lineH;
      }
      yPos = mnuY - noAllwdLines * lineH;
      let xPos = mnuX;
      return (
        <ClickOutsideDetector
          caller="dropDownCard"
          listen
          onClickOutside={() => {
            setOpen(false);
          }}
          onClick={() => {}}
        >
          <div style={{ position: "relative" }}>
            <ul
              ref={menuRef}
              className="UI-dropdown-list"
              style={{
                maxHeight: `${maxHgtMnu}px`,
                top: `${yPos}px`,
                left: isMobileTablet() ? `${xPos - 60}px` : `${xPos}px`,
                transition: "transform 0.2s ease-in-out"
              }}
            >
              <li className="UI-dropdown-header">Filter by:</li>
              {menuList.map((item, i) => {
                return (
                  <li
                    className="UI-dropdown-item-filters"
                    key={i}
                    onClick={() => {
                      setOpen(false);
                      item.callback(item.text);
                    }}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "100px"
                      }}
                    >
                      {item.text}
                    </span>
                    <span
                      style={{
                        display: "inline-block",
                        width: "1rem",
                        height: "1rem",
                        backgroundColor: item.color,
                        marginLeft: "0.5rem"
                      }}
                    ></span>
                  </li>
                );
              })}
            </ul>
          </div>
        </ClickOutsideDetector>
      );
    }
  };

  const handleFilter = (event) => {
    if (
      topicsState.labelDisplay?.length > 0 ||
      topicsState.flagDisplay ||
      topicsState.unreadDisplay ||
      topicsState.unreadDisplay
    ) {
      if (topicsState.unreadDisplay || topicsState.unreadDisplay) {
        topicsDispatch({
          type: "SET_UNREAD_DISPLAY",
          values: { unreadDisplay: !topicsState.unreadDisplay }
        });
      }
      if (topicsState.labelDisplay?.length > 0) {
        topicsDispatch({
          type: "SET_LABEL_DISPLAY",
          values: { labelDisplay: undefined }
        });
      }
      if (topicsState.flagDisplay) {
        topicsDispatch({
          type: "SET_FLAG_DISPLAY",
          values: { flagDisplay: false }
        });
      }
    } else {
      let found = false;
      if (
        (globalState.prefs?.labelNamesList &&
          globalState.prefs?.labelNamesList?.length > 0) ||
        (globalState.prefs?.clientStar &&
          globalState.prefs?.clientStar?.length > 0)
      ) {
        if (
          globalState.prefs?.labelNamesList &&
          globalState.prefs?.labelNamesList?.length > 0
        ) {
          for (let i = 0; i < globalState.prefs?.labelNamesList?.length; i++) {
            if (
              globalState.prefs?.labelNamesList[i]?.labels &&
              globalState.prefs?.labelNamesList[i]?.labels?.length > 0
            ) {
              found = true;
              break;
            }
          }
        }
        if (
          globalState.prefs?.clientStar &&
          globalState.prefs?.clientStar?.length > 0
        ) {
          found = true;
        }
      }
      if (found) {
        setOpen((open) => !open);
        setMnuX(event.clientX);
        setMnuY(event.clientY);
      } else {
        //if no labels show topics with unread messages
        topicsDispatch({
          type: "SET_UNREAD_DISPLAY",
          values: { unreadDisplay: !topicsState.unreadDisplay }
        });
      }
    }
  };

  const handleCondense = (event) => {
    event.preventDefault();
    setCollectionDisplay("collapse");
    setHighlight(true);
  };

  const handleExpand = (event) => {
    event.preventDefault();
    setCollectionDisplay("expand");
  };

  let content = useMemo(
    () => (
      <div id="contentDiv">
        {open && loadMenu()}
        {globalState.isLoggedIn ? (
          <div
            className={isMobileTablet() ? "UI" : "UISs"}
            style={
              !isMobileTablet()
                ? {
                    gridTemplateColumns:
                      screenWidth <= minColWidth //300
                        ? "1fr"
                        : screenWidth <= maxColWidth //600
                        ? "1fr"
                        : screenWidth <= maxColWidth + minColWidth //900
                        ? topicsState.showTopic?.subscription &&
                          topicsState.showTopic?.subscription !== undefined
                          ? `minmax(${minColWidth}px, ${maxColWidth}px) minmax(${minColWidth}px, ${maxColWidth}px)`
                          : `${maxColWidth}px 1fr`
                        : screenWidth <= 3 * maxPartColWidth //1050
                        ? topicsState.showTopic?.subscription &&
                          topicsState.showTopic?.subscription !== undefined
                          ? `minmax(${minColWidth}px, ${maxColWidth}px) minmax(${minColWidth}px, ${maxColWidth}px) minmax(${minColWidth}px, ${maxPartColWidth}px)`
                          : `${maxColWidth}px 1fr`
                        : screenWidth <= 2 * maxColWidth + maxPartColWidth //1550
                        ? topicsState.showTopic?.subscription &&
                          topicsState.showTopic?.subscription !== undefined
                          ? `minmax(${minColWidth}px, 1fr) minmax(${minColWidth}px, 1fr) minmax(${minColWidth}px, ${maxPartColWidth}px)`
                          : `${maxColWidth}px 1fr`
                        : topicsState.showTopic?.subscription &&
                          topicsState.showTopic?.subscription !== undefined
                        ? `${maxColWidth}px 1fr ${maxPartColWidth}px`
                        : `${maxColWidth}px 1fr`,
                    height: intFrameHeight
                      ? intFrameHeight
                      : window.innerHeight,
                    backgroundColor: globalState.displaying_archive
                      ? "var(--main_screen_background_archive_color)"
                      : "var(--main_screen_background_color)"
                  }
                : {
                    height: intFrameHeight
                      ? intFrameHeight
                      : window.innerHeight,
                    backgroundColor: globalState.displaying_archive
                      ? "var(--main_screen_background_archive_color)"
                      : "var(--main_screen_background_color)"
                  }
            }
          >
            <div
              className={
                isMobileTablet()
                  ? "UI-frame"
                  : topicsState.showTopic?.subscription &&
                    topicsState.showTopic?.subscription !== undefined &&
                    screenWidth <= maxColWidth
                  ? "dispnone"
                  : "col-1"
              }
              style={
                UIFrameHeight
                  ? { height: UIFrameHeight }
                  : { height: window.innerHeight }
              }
            >
              {/* <UserProfile /> */}
              <UIPersonaManager setModal={setModal} />
              {globalState.showWorldIDScreen && <UIWorldIDInfo />}
              <UITopicList
                key={collectionDisplay}
                share={showShare}
                collectionDisplay={collectionDisplay}
                setCollectionDisplay={setCollectionDisplay}
              />{" "}
              {globalState.isLoggedIn && !globalState.showWorldIDScreen && (
                <div className="UI-main-screen-fab-area">
                  <div
                    className={
                      isMobileTablet()
                        ? highlight
                          ? "UI-main-screen-fab-mobile-active"
                          : "UI-main-screen-fab-mobile"
                        : highlight
                        ? "UI-main-screen-fab-active"
                        : "UI-main-screen-fab"
                    }
                    title="Condense all topics to collections / folders"
                    onClick={handleCondense}
                  >
                    <span>
                      <CgArrowsMergeAltV
                        style={{ width: "1.5rem", height: "1.5rem" }}
                      />
                    </span>
                  </div>
                  <div
                    className={
                      isMobileTablet()
                        ? "UI-main-screen-fab-mobile"
                        : "UI-main-screen-fab"
                    }
                    title="Expand all collections / folders to show topic"
                    onClick={handleExpand}
                  >
                    <span>
                      <CgArrowsShrinkV
                        style={{ width: "1.5rem", height: "1.5rem" }}
                      />
                    </span>
                  </div>
                  <div
                    className={
                      isMobileTablet()
                        ? topicsState.unreadDisplay ||
                          topicsState.labelDisplay?.length > 0 ||
                          topicsState.flagDisplay
                          ? "UI-main-screen-fab-mobile-active"
                          : "UI-main-screen-fab-mobile"
                        : topicsState.unreadDisplay ||
                          topicsState.labelDisplay?.length > 0 ||
                          topicsState.flagDisplay
                        ? "unread-element-active"
                        : "unread-element"
                    }
                    title="Filter on unread messages and labels"
                    onClick={handleFilter}
                  >
                    {topicsState.unreadDisplay ? (
                      <MdOutlineFilterAltOff size={32} />
                    ) : (
                      <MdOutlineFilterAlt size={32} />
                    )}
                  </div>
                  <span
                    className="UI-main-screen-fab"
                    onClick={() => {
                      setModal(
                        <UISearchMyTopicsModal
                          onClose={() => setModal(undefined)}
                          // hitOnClick={(val) => handleUITopicClick(val)}
                        ></UISearchMyTopicsModal>
                      );
                    }}
                  >
                    <FaSearch size="20" />
                  </span>
                </div>
              )}
            </div>
            {!isMobileTablet() && topicsState.showTopic?.subscription && (
              <div className="col-2">
                <UITopicContainer
                  showback={true}
                  metadata={topicsState.showTopic}
                  type="here"
                ></UITopicContainer>
              </div>
            )}
            <div
              className={
                topicsState.showTopic?.subscription
                  ? screenWidth > 3 * minColWidth
                    ? "col-3"
                    : "dispnone"
                  : screenWidth > maxColWidth
                  ? "col-3"
                  : "dispnone"
              }
              style={
                screenWidth > maxColWidth + minColWidth
                  ? { flexBasis: "33%" }
                  : {}
              }
            >
              {topicsState.showTopic?.subscription ? (
                <UIParticipants
                  sub={topicsState?.showTopic?.subscription}
                  transferSubgroup={(val) => setSubgroup(val)}
                  col3={true}
                />
              ) : (
                <UITopicTest />
              )}
            </div>
            {modal}
          </div>
        ) : globalState.jwtSignup ? (
          <UIRegister
            email={globalState.payloadSignup?.email}
            jwtSignup={globalState.jwtSignup}
          />
        ) : (
          <UIFlash pathname={props.location.pathname} />
        )}
      </div>
    ),
    [
      UIFrameHeight,
      collectionDisplay,
      globalState.displaying_archive,
      globalState.isLoggedIn,
      globalState.showWorldIDScreen,
      intFrameHeight,
      modal,
      showShare,
      topicsState.unreadDisplay,
      globalState.jwtSignup,
      liveUser,
      topicsState.showTopic,
      topicsState.showTopic?.subscription,
      topicsState.labelDisplay,
      topicsState.flagDisplay,
      screenWidth,
      open,
      highlight
    ]
  );

  return content;
};

export default React.memo(UILogin, (prevProps, nextProps) => {
  return true; //prevProps === nextProps;
});
